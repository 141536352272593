<script setup lang="ts">
const prepareImgUrl = useImage();
const images = [
	{
		width: 39,
		height: 38
	},
	{
		width: 53,
		height: 55
	},
	{
		width: 31,
		height: 29
	},
	{
		width: 72,
		height: 75
	}
];
</script>

<template>
	<div class="prize-holder">
		<picture class="image">
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/jackpot/prize-mob.png', {
						format: 'avif',
						width: 80,
						height: 75,
						loading: 'lazy'
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg
				src="/nuxt-img/jackpot/prize-desk.png"
				format="avif"
				width="111"
				height="104"
				alt="prize"
				loading="lazy"
			/>
		</picture>
		<div v-for="(imageNumber, index) in images" :key="index" :class="`star star-${index + 1}`">
			<NuxtImg
				:width="images[index]?.width"
				:height="images[index]?.height"
				:src="`/nuxt-img/jackpot/stars/star-${index + 1}.png`"
				alt="prize"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.prize-holder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.image {
	position: absolute;
	right: 255px;
	top: 49%;
	animation: levitation 2s ease-in-out 1s infinite;

	@include media-breakpoint-down(sm) {
		right: 19%;
		top: 160px;
		z-index: 1;

		img {
			width: 80px;
			height: 75px;
		}
	}
}

:deep(.star) {
	position: absolute;
	width: max-content;
}

:deep(.star-1) {
	top: 22%;
	right: 385px;
	animation: swingAndScale 3s linear infinite;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

:deep(.star-2) {
	top: 80%;
	right: 320px;
	animation: swingAndScale 3s linear 2s infinite;

	@include media-breakpoint-down(sm) {
		top: 19%;
		right: 79%;
	}
}

:deep(.star-3) {
	top: 82%;
	right: 60px;
	animation: swingAndScale 2s linear 1s infinite;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

:deep(.star-4) {
	top: 38%;
	right: 20px;
	animation: swingAndScale 2s linear 1s infinite;

	@include media-breakpoint-down(sm) {
		top: 80px;
		right: 27px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 24px;
			height: 24px;
		}
	}
}

@keyframes levitation {
	0% {
		margin-top: 0;
	}

	50% {
		margin-top: -20px;
	}

	100% {
		margin-top: 0;
	}
}

@keyframes swingAndScale {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	50% {
		transform: rotate(-15deg);
	}

	75% {
		transform: rotate(15deg);
	}
}
</style>
